$angle: 35deg;

.breadcrumb {
  background-color: $colorGallery;
  border-top: 1px solid $colorSilverChalice;
  border-bottom: 1px solid $colorSilverChalice;
}

.breadcrumb-inner {
  @include tabletSmallMax {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 4px 0;
  }
}

.breadcrumb-item {
  position: relative;
  display: inline-block;
  padding: 9px 0 10px;
  color: $colorSilverChalice;
  overflow: hidden;
  transition: color $transitionDurationDefault ease;

  &::before,
  &::after {
    content: '';
    position: absolute;
    right: 0;
    top: 50%;
    height: calc(50% / #{cos($angle)});
    width: 2px;
    transform: rotate($angle);
    transform-origin: top;
    background-color: $colorSilverChalice;
  }

  i {
    display: none;
    position: absolute;
    right: 0;
    top: 50%;
    padding-top: 2px;
    font-size: 8px;
    transform: translateY(-50%);
    color: $colorText;
    transition: color $transitionDurationDefault ease;
  }

  &::after {
    top: auto;
    bottom: 50%;
    transform: rotate(-$angle);
    transform-origin: bottom;
  }

  &:hover {
    color: $colorGlacier;
  }

  &.active {
    color: $colorGlacier;
    font-weight: 700;

    &::after,
    &::before {
      display: none;
    }
  }

  &:not(:nth-child(2)) {
    padding-left: 14px;
  }

  &:not(:last-child) {
    padding-right: 35px;
  }

  &--back {
    display: none;

    i {
      position: absolute;
      left: 0;
      right: auto;
      transform: translateY(-50%) rotate(180deg);
    }
  }

  @include tabletSmallMax {
    display: none;
    padding: 0;
    color: $colorText;
    flex-grow: 0;
    flex-shrink: 0;
    white-space: nowrap;

    i {
      display: block;
    }

    &:not(:nth-child(2)) {
      padding-left: 0;
    }

    &:not(:last-child) {
      padding-right: 0;
    }

    &::after,
    &::before {
      display: none;
    }

    &.active {
      flex-shrink: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 0 0 0 4px;
    }

    &--back {
      border-right: 1px solid $colorText;
    }

    &:nth-last-child(2) {
      padding: 0 12px 0 10px;
    }

    &--back:first-child {
      padding: 0 10px 0 15px;
    }

    &:hover {
      i {
        color: $colorGlacier;
      }
    }

    &:nth-last-child(2),
    &.active,
    &--back {
      display: inline-block;
    }
  }
}
