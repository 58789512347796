/* =================================================
        Headlines.
================================================= */

.headline-special {
  font-size: 48px;
  line-height: 1.125;
  font-weight: 500;
  font-family: $fontDefaultHeadline;

  @include mobileLargeMax {
    font-size: 34px;
  }
}

.headline-first {
  font-size: 48px;
  line-height: 1.125;
  font-weight: 400;
  font-family: $fontDefault;

  @include mobileLargeMax {
    font-size: 34px;
  }

  &--cart {
    @include mobileLargeMax {
      font-size: 18px;
    }
  }
}

.headline-second {
  font-size: 37px;
  line-height: 1.162;
  font-weight: 400;
  font-family: $fontDefault;

  @include mobileLargeMax {
    font-size: 22px;
  }
}

.headline-third {
  font-size: 22px;
  font-weight: 400;
  line-height: 1.18;
  font-family: $fontDefault;

  @include mobileLargeMax {
    font-size: 18px;
  }
}

.headline-fourth {
  font-size: 22px;
  line-height: 1.13;
  font-weight: 700;
  font-family: $fontDefault;

  @include mobileLargeMax {
    font-size: 18px;
  }
}

.headline-fifth {
  font-size: 18px;
  line-height: 1.16;
  font-weight: 700;
  font-family: $fontDefault;

  @include mobileLargeMax {
    font-size: 14px;
  }
}
