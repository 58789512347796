@use 'sass:math';

$layout-order-check--gutter: $gutter !default;

.layout-order-check {
  display: flex;
  flex-wrap: wrap;
  margin-right: math.div($layout-order-check--gutter, -2);
  margin-left: math.div($layout-order-check--gutter, -2);
}

.layout-order-check-header,
.layout-order-check-main,
.layout-order-check-aside {
  flex: 1 1 auto;
  padding: $layout-order-check--gutter * 0.5;
}

.layout-order-check-header {
  flex: 0 0 100%;
}

.layout-order-check-main {
  flex-basis: 830px;

  @include mobileLargeMax {
    max-width: 100%;
  }
}

.layout-order-check-aside {
  flex-basis: 400px;
}

.agbText {
  line-height: 1.5;
}

a.agbLink {
  text-decoration: underline;
  &:hover {
    color: $colorAvailable;
  }
}

.form input[type=checkbox] + label:hover, .form input[type=radio] + label:hover {
  color: unset;
}
