/* You can add global styles to this file, and also import other style files */
@import './theme/library/theme-weyland/base/variables';
@import './theme/library/theme-weyland/base/fonts';
@import './theme/library/main';
@import './theme/library/theme-weyland/base/buttons';
@import './theme/library/theme-weyland/base/bg-section';
@import './theme/library/theme-weyland/base/edit-hover';

@import './theme/library/theme-weyland/base/form';
@import './theme/library/base/headlines';
@import './theme/library/base/icons';
@import './theme/library/base/buttons';
@import './theme/library/theme-weyland/base/link-hover';
@import './theme/library/theme-weyland/base/swiper';
@import './theme/library/theme-weyland/base/layouts/layout-order-check';
@import './theme/library/theme-weyland/components/accordion-item';
@import './theme/library/theme-weyland/components/buttons/back-to-top';
@import './theme/library/theme-weyland/components/buttons/btn-slim';
@import './theme/library/theme-weyland/components/aside-nav';
@import './theme/library/theme-weyland/components/count';
@import './theme/library/theme-weyland/components/footer/footer-main';
@import './theme/library/theme-weyland/components/footer/footer-main-nav-side';
@import './theme/library/theme-weyland/components/header/header-dropdown';
@import './theme/library/theme-weyland/components/header/header-info';
@import './theme/library/theme-weyland/components/header/header-main';
@import './theme/library/theme-weyland/components/header/header-nav';
@import './theme/library/theme-weyland/components/header/header-search';
@import './theme/library/theme-weyland/components/header/header-search-result-products-item';
@import './theme/library/theme-weyland/components/header/navigation-item';
@import './theme/library/theme-weyland/components/header/navigation-main-item';
@import './theme/library/theme-weyland/components/login/login';
@import './theme/library/theme-weyland/components/mini-cart';
@import './theme/library/theme-weyland/components/product/article-item';
@import './theme/library/theme-weyland/components/product/card-product';
@import './theme/library/theme-weyland/components/product/listing-active-filters';
@import './theme/library/theme-weyland/components/product/listing-filters';
@import './theme/library/theme-weyland/components/product/listing-filters-title';
@import './theme/library/theme-weyland/components/product/listing-filters-item';
@import './theme/library/theme-weyland/components/product/listing-sort';
@import './theme/library/theme-weyland/components/tiles/masonry-grid-tile';
@import './theme/library/theme-weyland/components/tiles/process-tile';
@import './theme/library/includes/breadcrumb';
@import './theme/library/theme-weyland/includes/breadcrumb';
@import './theme/library/theme-weyland/includes/cms/newsletter';
@import './theme/library/theme-weyland/includes/cms/socials';
@import './theme/library/theme-weyland/includes/cms/videos';
@import './theme/library/theme-weyland/includes/header/header';
@import './theme/library/theme-weyland/includes/header/header-detail';

@import '~@angular/material/theming';
@import '~@angular/material/prebuilt-themes/indigo-pink.css';

// Add Nunito font to angular material
$custom-typography: mat-typography-config(
  $font-family: $fontDefault,
);

@include mat-core($custom-typography);

@import './theme/library/theme-weyland/material_overwrites';

// Tippy (tooltip)
@import '~tippy.js/dist/tippy.css';
@import '~tippy.js/themes/light.css';
@import '~tippy.js/animations/scale.css';

// Toastr
@import './theme/library/theme-weyland/plugins/toastr';
@import './theme/library/theme-weyland/plugins/cookie-consent';
